const variables = {
  darkColor: "#222831",
  clearColor: "#eeeeee",
  greyColor: "#393e46",
  accentColor: "#7971ea",

  padding: "20px",

  font: `"Poppins", sans-serif`,
}

export default variables
